















import {Component, Prop, Vue} from 'vue-property-decorator';
import Player from '@/model/core/player.model';
import PlayerPicture from '@/components/player/PlayerPicture.vue';
import RosterPlayerTitle from '@/components/player/RosterPlayerTitle.vue';

@Component({
  components: {RosterPlayerTitle, PlayerPicture},
})
export default class PlayerCard extends Vue {

  @Prop({type: Object, required: true}) player!: Player;
  @Prop({type: Boolean, required: false, default: false}) showJerseyNumbers!: boolean;
  @Prop({tupe: Array, required: false, default: []}) playerAttributes!: () => [];

}
