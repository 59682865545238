


























import {Component, Inject, Prop, Vue} from 'vue-property-decorator';
import MetaMixin from '@/mixins/meta.mixin';
import RouteMixin from '@/mixins/route.mixin';
import {Box, HfPage} from '@/model/site/components.model';
import {Getter} from 'vuex-class';
import {Context} from '@/model/site/context.model';
import {CONTEXT} from '@/store/context.store';
import HfAdBox from '@/components/ads/HfAdBox.vue';
import PanelBox from '@/components/ui/PanelBox.vue';
import {BackgroundArticle, BackgroundSideArticle, Headline} from '@/model/article/article.model';
import {BackgroundArticleService} from '@/services/background-article.service';
import {nil} from '@/utils/common.util';
import BackgroundSideList from '@/components/background/BackgroundSideList.vue';
import BackgroundBackLink from '@/components/background/BackgroundBackLink.vue';
import BackgroundSideContent from '@/components/background/BackgroundSideContent.vue';
import ArticleDisplay from '@/components/ArticleDisplay.vue';
import DateFilters from '@/mixins/date-filters.mixin';
import SportLayout from '@/components/layout/SportLayout.vue';
import SideBoxes from '@/components/layout/SideBoxes.vue';
import GoldbachOutstream from '@/components/ads/GoldbachOutstream.vue';
import MobileMixin from '@/mixins/mobile.mixin';

@Component({
  mixins: [MetaMixin, RouteMixin, DateFilters, MobileMixin],
  metaInfo() {
    return this.metaInfo;
  },
  components: {GoldbachOutstream, HfAdBox, SideBoxes, SportLayout, ArticleDisplay, PanelBox},
})
export default class BackgroundPage extends Vue implements HfPage {

  @Prop({required: true}) id!: number;
  @Getter(CONTEXT) context!: Context;
  @Inject('backgroundArticleService') backgroundArticleService!: BackgroundArticleService;

  article: BackgroundArticle = nil();
  headlines: Headline[] = nil();
  authorHeadlines: Headline[] = nil();

  get headTitle() {
    if (this.article) {
      return this.article.title;
    }
    return undefined;
  }

  get headDescription() {
    if (this.article) {
      return this.article.lead;
    }
    return undefined;
  }

  get canonicalUrl(): string {
    return this.urlFromCurrentPath;
  }

  get boxes(): Box[] {
    const backlink: Box = {
      title: 'Background-Portal',
      component: BackgroundBackLink,
      props: {}
    };
    const headlines: Box = {
      title: 'Letzte Beiträge',
      component: BackgroundSideList,
      props: {headlines: this.headlines}
    };
    const ad: Box = {
      component: HfAdBox,
      props: {formatKey: 'rectangle', targets: ['desktop']},
    };
    let boxes = [backlink, headlines];
    const children = this.children(this.article);
    if (children.length >= 1) {
      boxes = [...boxes, children[0]];
    }
    boxes = [...boxes, ad];
    if (children.length > 1) {
      boxes = [...boxes, ...children.slice(1, children.length)];
    }
    const authorsHeadlines: Box = {
      title: this.author,
      component: BackgroundSideList,
      props: {headlines: this.authorHeadlines}
    };
    boxes = [...boxes, authorsHeadlines];
    return boxes;
  }

  get author(): string {
    if (this.article) {
      return this.article.authorDesc;
    }
    return 'Author';
  }

  created() {
    this.backgroundArticleService.detail(this.id)
      .then((article: BackgroundArticle) => {
        this.article = article;
        if (this.article) {
          const latestAuthorHeadlines = this.context.configuration.background.latestAuthorHeadlines;
          this.backgroundArticleService.authorHeadlines(this.article.author, latestAuthorHeadlines)
            .then((headlines: Headline[]) => this.authorHeadlines = headlines)
            .catch((ignore: any) => {
            });
        }
      })
      .catch((error: any) => {
        this.$router.push(`/404?path=${this.$route.fullPath}&message=${error}`);
      });
    const latestSideCnt = this.context.configuration.background.latestArticlesDetailSide;
    this.backgroundArticleService.headlines(latestSideCnt)
      .then((headlines: Headline[]) => this.headlines = headlines)
      .catch((ignore: any) => {
      });
  }

  body(article: BackgroundArticle) {
    const hasIframe = article.body && article.body.indexOf('iframe') >= 0;
    if (hasIframe) {
      // IMPROVMENT [background] responsive iframe/video-integration (#1137 is ok, #1223 not)
      this.$log.info(`Background-article ${article.id} contains iframe, check it's integration`);
    }
    return article.body;
  }

  children(article: BackgroundArticle): Box[] {
    if (!article) {
      return [];
    }
    return article.children.map((child: BackgroundSideArticle) => {
      return {
        title: child.title,
        component: BackgroundSideContent,
        props: {content: child.body}
      };
    });
  }

}
