






























import {Component, Prop, Vue} from 'vue-property-decorator';
import {Article} from '@/model/article/article.model';

@Component({
  components: {},
})
export default class ArticleDisplay extends Vue {
  @Prop({required: true}) article!: Article;
  @Prop({default: false}) suppressSubTitle!: boolean;
}
