








































import {Component, Inject, Vue} from 'vue-property-decorator';
import {Box, HfPage} from '@/model/site/components.model';
import {Getter} from 'vuex-class';
import {CONTEXT} from '@/store/context.store';
import {Context} from '@/model/site/context.model';
import RouteMixin from '@/mixins/route.mixin';
import MetaMixin from '@/mixins/meta.mixin';
import MobileMixin from '@/mixins/mobile.mixin';
import {RosterService} from '@/services/roster.service';
import SelectionTitle from '@/components/ui/SelectionTitle.vue';
import EventSeasonSelection from '@/components/shared/selection/EventSeasonSelection.vue';
import PanelBox from '@/components/ui/PanelBox.vue';
import SportLayout from '@/components/layout/SportLayout.vue';
import SideBoxes from '@/components/layout/SideBoxes.vue';
import HfAdBox from '@/components/ads/HfAdBox.vue';
import Loading from 'vue-loading-overlay';
import TEAM_SERVICE from '@/services/team.service';
import SEASON_SERVICE from '@/services/season.service';
import {Season, Team, TeamId} from '@/model/core/core-data.model';
import PlayerCard from '@/components/player/PlayerCard.vue';
import Player from '@/model/core/player.model';
import {Roster, RosterKey} from '@/model/core/roster.model';
import PlayerPicture from '@/components/player/PlayerPicture.vue';
import CountryFlag from '@/components/shared/CountryFlag.vue';
import PlayerBox from '@/components/player/PlayerBox.vue';
import RosterPlayerTitle from '@/components/player/RosterPlayerTitle.vue';
import EventTeams from '@/components/event/EventTeams.vue';
import RosterTeams from '@/components/team/RosterTeams.vue';

const xml2js = require('xml2js');

@Component({
  mixins: [MetaMixin, RouteMixin, MobileMixin],
  metaInfo() {
    return this.metaInfo;
  },
  components: {
    RosterPlayerTitle,
    PlayerBox,
    CountryFlag,
    PlayerPicture,
    PlayerCard,
    HfAdBox,
    SideBoxes,
    SportLayout,
    PanelBox,
    EventSeasonSelection,
    SelectionTitle,
    Loading
  },
})
export default class RosterPage extends Vue implements HfPage {

  @Inject('rosterService') rosterService!: RosterService;
  @Getter(CONTEXT) context!: Context;

  team?: Team = {} as Team;
  roster: Roster = {} as Roster;
  nlaTeams: TeamId[] = [];
  nlbTeams: TeamId[] = [];

  get title() {
    if (!this.team) {
      return 'Kader';
    }
    return `Kader ${this.team.longName}`;
  }

  get headTitle() {
    return `${this.title}`;
  }

  get headDescription() {
    return 'TODO: Description';
  }

  get canonicalUrl() {
    return this.urlFromCurrentPath;
  }

  async created() {
    this.$wait.start('rosterPage');
    if (this.teamId) {
      TEAM_SERVICE.teamById(this.teamId, this.ofSeason.seasonId, ['a', 'b']).then((response: any) => {
        this.team = response.data;
      }).catch((error) => {
        this.$log.error('Error getting team', error);
      });
      TEAM_SERVICE.teams(this.ofSeason.seasonId, 'a')
        .then((teamData: any) => this.nlaTeams = this.toTeamIds(teamData));
      TEAM_SERVICE.teams(this.ofSeason.seasonId, 'b')
        .then((teamData: any) => this.nlbTeams = this.toTeamIds(teamData));
      await this.rosterService.rosterForTeam(this.teamId).then((roster: Roster) => {
        this.roster = roster;
        Object.keys(this.roster.allPlayers).forEach(playerId => {
          this.roster.allPlayers[playerId] = new Player(this.roster.allPlayers[playerId]);
        });
      }).finally(() => {
        this.$wait.end('rosterPage');
      });
    }
  }

  toTeamIds(teams: any): TeamId[] {
    const teamIds: TeamId[] = [];
     Object.keys(teams).forEach((teamId: string) => {
      teamIds.push({
        teamId: teams[teamId].teamId,
        display: teams[teamId].longName,
        countryId: 'sui'
     });
    });
    return teamIds;
  }

  get teamId() {
    return this.$route.params.teamId;
  }

  get seasonId() {
    return this.$route.params.seasonId;
  }

  get mainPositions() {
    return [
      {
        rosterDescription: 'Torhüter',
        players: this.goalkeepers
      },
      {
        rosterDescription: 'Verteidiger',
        players: this.defenders
      },
      {
        rosterDescription: 'Stürmer',
        players: this.forwards
      },
      {
        rosterDescription: 'Coaches',
        players: this.coaches
      }
    ];
  }

  get goalkeepers() {
    return this.playersByRosterKey('goalkeepers');
  }

  get defenders() {
    return this.playersByRosterKey('defenders');
  }

  get forwards() {
    return this.playersByRosterKey('forwards');
  }

  get coaches() {
    return [
      ...this.playersByRosterKey('coaches'),
      ...this.playersByRosterKey('assistantCoaches')
    ];
  }

  get alumni() {
    return this.playersByRosterKey('alumni');
  }

  get juniors() {
    return this.playersByRosterKey('juniors');
  }

  private playersByRosterKey(rosterKey: RosterKey): Player[] {
    const players: Player[] = [];
    if (this.roster && this.roster[rosterKey]) {
      this.roster[rosterKey].forEach(playerId => {
        const player = this.roster.allPlayers[playerId];
        if (!player.isJunior() || rosterKey === 'juniors') {
          players.push(player);
        }
      });
    }
    return players;
  }

  positionTitle(position: any) {
    return position.rosterDescription;
  }

  playerAttributes(player: Player) {
    const attrs: string[] = [];
    if (!player) {
      return attrs;
    }

    // position
    if (player.isCoachOrAssistant()) {
      attrs.push(player.position === 'T' ? 'Coach' : 'Assistant');
    } else if (player.isJunior()) {
      attrs.push(this.$t(`position.${player.position}`));
    }

    const age = player.age(true);
    if (age) {
      attrs.push(`${age} Jahre`);
    }
    if (player.birthday()) {
      attrs.push(`${player.birthday('DD-MM-YYYY')}`);
    }
    if (player.height()) {
      attrs.push(`${player.height()}`);
    }
    if (player.weight()) {
      attrs.push(`${player.weight()}`);
    }
    return attrs;
  }

  get ofSeason(): Season {
    if (this.seasonId) {
      return SEASON_SERVICE.toSeason(this.seasonId);
    }
    return SEASON_SERVICE.current();
  }

  get teamIds() {
    if (this.team) {
      if (this.team.leagueId === 'a') {
        return this.nlaTeams;
      } else if (this.team.leagueId === 'b') {
        return this.nlbTeams;
      }
    }
    // else
    return undefined;
  }

  get boxes(): Box[] {
    const boxes: Box[] = [];

    if (this.alumni && this.alumni.length) {
      const alumniPlayers: Box = {
        title: 'Weitere Spieler',
        component: PlayerBox,
        props: {
          players: this.alumni,
          playerAttributesFunction: this.playerAttributes
        }
      };
      boxes.push(alumniPlayers);
    }

    if (this.juniors && this.juniors.length) {
      const juniorPlayers: Box = {
        title: 'Elite-Junioren',
        component: PlayerBox,
        props: {
          players: this.juniors,
          playerAttributesFunction: this.playerAttributes
        }
      };
      boxes.push(juniorPlayers);
    }

    const teamIds = this.teamIds;
    if (teamIds) {
      const teams: Box = {
        component: RosterTeams,
        title: 'Teams',
        props: {
          teams: this.teamIds,
          seasonId: this.seasonId,
          leagueId: this.leagueId
        }
      };
      boxes.push(teams);
    }

    const adBox: Box = {
      component: HfAdBox,
      props: {formatKey: 'rectangle'}
    };
    boxes.push(adBox);

    return boxes;
  }




}
