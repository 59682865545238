
















































import {Component, Inject, Vue} from 'vue-property-decorator';
import MetaMixin from '@/mixins/meta.mixin';
import RouteMixin from '@/mixins/route.mixin';
import {Box, HfPage} from '@/model/site/components.model';
import {Getter} from 'vuex-class';
import {Context} from '@/model/site/context.model';
import {CONTEXT} from '@/store/context.store';
import HfAdBox from '@/components/ads/HfAdBox.vue';
import PanelBox from '@/components/ui/PanelBox.vue';
import {BackgroundArticle, Headline} from '@/model/article/article.model';
import {BackgroundArticleService} from '@/services/background-article.service';
import BackgroundSideList from '@/components/background/BackgroundSideList.vue';
import DateFilters from '@/mixins/date-filters.mixin';
import ArticleDisplay from '@/components/ArticleDisplay.vue';
import SportLayout from '@/components/layout/SportLayout.vue';
import SideBoxes from '@/components/layout/SideBoxes.vue';
import GoldbachOutstream from '@/components/ads/GoldbachOutstream.vue';
import MobileMixin from '@/mixins/mobile.mixin';

@Component({

  mixins: [MetaMixin, RouteMixin, DateFilters, MobileMixin],
  metaInfo() {
    const metaInfo = this.metaInfo;
    return this.replaceMetaProperty(metaInfo, 'og:type', 'article');
  },
  components: {GoldbachOutstream, SideBoxes, SportLayout, ArticleDisplay, PanelBox, HfAdBox},
})
export default class BackgroundPage extends Vue implements HfPage {

  @Getter(CONTEXT) context!: Context;
  @Inject('backgroundArticleService') backgroundArticleService!: BackgroundArticleService;

  mainArticles: BackgroundArticle[] = [];
  headlines: Headline[] = [];
  picturesShown = 0;

  isFirst(articleId: number, articles: BackgroundArticle[]) {
    return articles.find(a => a.id === articleId) !== undefined;
  }

  get headTitle() {
    return 'Hintergründe & Meinungen';
  }

  get headDescription() {
    return undefined;
  }

  get canonicalUrl(): string {
    return this.urlFromCurrentPath;
  }

  get maxPicturesShown() {
    return this.context.configuration.background.maxPicturesShown;
  }

  get boxes(): Box[] {
    const side: Box = {
      title: 'Weitere Beiträge',
      component: BackgroundSideList,
      props: {headlines: this.headlines}
    };
    const ad: Box = {
      component: HfAdBox,
      props: {formatKey: 'rectangle', targets: ['desktop']},
    };
    return [side, ad];
  }

  created() {
    const latestMainCnt = this.context.configuration.background.latestArticlesOverviewMain;
    const latestSideCnt = this.context.configuration.background.latestArticlesOverviewSide;
    const latestCnt = latestMainCnt + latestSideCnt;
    this.backgroundArticleService.latest(latestMainCnt)
      .then((articles: BackgroundArticle[]) => {
        this.mainArticles = articles;
        this.mainArticles.forEach(a => this.stripPicture(a));
      })
      .catch((ignore: any) => {
      });
    this.backgroundArticleService.headlines(latestCnt)
      .then((headlines: Headline[]) => this.headlines = headlines)
      .catch((ignore: any) => {
      });
  }

  private stripPicture(article: BackgroundArticle) {
    if (article.pictureURL && this.picturesShown < this.maxPicturesShown) {
      this.picturesShown++;
      return;
    }
    article.pictureURL = '';
  }
}
