import { render, staticRenderFns } from "./BackgroundSideContent.vue?vue&type=template&id=359bc766&functional=true&"
import script from "./BackgroundSideContent.vue?vue&type=script&lang=ts&"
export * from "./BackgroundSideContent.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  true,
  null,
  null,
  null
  
)

export default component.exports