














import {Component, Prop, Vue} from 'vue-property-decorator';
import HfTeamLogo from '@/components/shared/HfTeamLogo.vue';
import {TeamId} from '@/model/core/core-data.model';

@Component({
  components: {HfTeamLogo},
})
export default class RosterTeams extends Vue {
  @Prop({required: true, type: Array}) teams!: TeamId[];
}
