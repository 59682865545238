import Vue from 'vue';
import {Mixin} from 'vue-mixin-decorator';

@Mixin
export default class RouteMixin extends Vue {

	urlForPath(path: string): string {
		const vm = this;
		if (path && !path.startsWith('/')) {
			path = `/${path}`;
		}
		if (vm.$router.mode === 'hash') {
			path = '#' + path;
		}
		let origin = window.location.origin;
		if (origin && path && !origin.endsWith('/') && !path.startsWith('/')) {
			origin = `${origin}/`;
		}
		return `${origin}${path}`;
	}

	get urlFromCurrentPath(): string {
		return this.urlForPath(this.$route.fullPath);
	}

}
