










import {Component, Vue, Prop} from 'vue-property-decorator';
import Player from '@/model/core/player.model';
import RosterPlayerTitle from '@/components/player/RosterPlayerTitle.vue';

@Component({
  components: {RosterPlayerTitle},
})
export default class PlayerBox extends Vue {

  @Prop({type: Array, required: true}) players!: Player[];
  @Prop({type: Function, required: true}) playerAttributesFunction!: () => [];

}
